/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var windowHeight = $(window).width();
        $('document').ready(function(){
          $('body').addClass('go');
           stickyNav();
           stickyMenu();
        });

        $('.tribe-events-month-event-title .url').click(function(e){
          e.preventDefault();
        });

        $(window).resize(function(){ stickyNav(); stickyMenu(); });
        
        function stickyMenu(){
           var windowHeight = $(window).width();

            if(windowHeight >= 768) { 
              var waypoint = new Waypoint({
                element: document.getElementById('fullmenu'),
                handler: function(direction) {
                  if(direction == "down"){
                    $('.fullmenu').addClass('go');
                    $('.wrap').css('margin-top', '49px');
                  } else {
                    $('.fullmenu').removeClass('go');
                     $('.wrap').css('margin-top', '0');
                  }
                }
              });
            }
        }

        // Search 
        $('.search-btn').click(function(){
          $('.searchbox').addClass('go');
          setTimeout(function(){
    $('.search-field').focus();
    console.log('asdf');
}, 200);

          
        });

        $('.closesearch').click(function(){
          $('.searchbox').removeClass('go');
        });

        $( ".search-form" ).click(function(){
           $( ".subsearch" ).submit();
        });

bios();
        // Bios
      //   $('document').bind('click', function(){
      //   $('.fullbios').click(function(){
      //     console.log('booty 3');
      //     var bio = $(this).attr('data-bio');
      //     //console.log(bio);
      //     $('.bio-over .center-it .biowrap').html(bio);
      //     $('.bio-over').addClass('go');
      //   });

      //   $('.closebio').click(function(){
      //     $('.bio-over').removeClass('go');
      //     $('.bio-over .center-it .biowrap').html('');
      //   });
      // });

        function bios(){
          $('.fullbios').click(function(){
            var bio = $(this).attr('data-bio');
            $('.bio-over .center-it .biowrap').html(bio);
            $('.bio-over').addClass('go');
          });

           $('.closebio').click(function(){
            $('.bio-over').removeClass('go');
            $('.bio-over .center-it .biowrap').html('');
          });
        }

        // Stick Nav
        function stickyNav(){
          var windowHeight = $(window).width();

          if(windowHeight >= 768) {
            $(".stickyside").stick_in_parent({
              offset_top: 100,
            });
          } else {
            $(".stickyside").trigger("sticky_kit:detach");
          }
        }

        $('.mob-nav').click(function(){
          $('.mobile-nav').addClass('go');
        });

        $('.submenu').click(function(){
          $('.side').addClass('go');
        });

        $('.closeit').click(function(){
          $('.mobile-nav').removeClass('go');
        });


        $('.closebtn').click(function(){
          $('.side').removeClass('go');
        });


        var reloadParent = false;
        var childTarget  = false;
        var parentID = false;

        $('.child a').click(function(e){
           e.preventDefault();
        });

        $('.parent a').click(function(e){
            e.preventDefault();

            if( $(window).width() <= 768){
              $('.side').removeClass('go');
            }

            var pageID = $(this).attr('data-link');
            var target = $(this).attr('data-target');
            var parentTarget = false;
            var currentPage = gpageID;
            

            // If we are on the same page just scroll to
            // the child element
            
            if( $(this).parent().parent().parent().hasClass('current') && currentPage === gpageID ){

              $('html, body').animate({
                scrollTop: $("#"+target).offset().top
              }, 300);

              currentPage = pageID

              reloadParent = false;
              return false;

            } else {
              //$('body').css('opacity', 0);
              // set global that tells use we have to reload
              // parent page content on next child click
              reloadParent = true;
            }


           


            // If we need to pull info from another
            // page, fetch that page and return it

            //check if we need to scroll to target
            // after loading parent content
            
            if( $(this).parent().hasClass('child') ){
              var childTarget = target;
              $('.stickyside ul li').removeClass('current');
              $(this).parent().parent().parent().addClass('current'); // Add current class to parent
            } else {
              parentTarget = true;
              $('.stickyside ul li').removeClass('current');
            }


            // $('html, body').animate({
            //     scrollTop: $(".target").offset().top
            // }, 300);
             
            setTimeout(function(){                        
              $('.main-c-wrap').addClass('moveout');    
            }, 100);
            

            $.ajax({
                    dataType: 'JSON',
                      url: ajaxurl,
                      data: {
                          'action':'page_data',
                          'target': target,
                          'pageID': pageID,
                      },
                      success: function(data){
                        var target = data.target;
                        $('.main-c-wrap').html(data.message);  
                        
                       
                        setTimeout(function(){    
                          
                            $('html, body').animate({
                                scrollTop: $("#"+target).offset().top - 50
                            }, 400);
  
                        }, 500);

                        setTimeout(function(){    
                                                
                            $('.main-c-wrap').removeClass('moveout');
                        }, 900);


                        //  $('html, body').animate({
                        //         scrollTop: $("#"+target).offset().top - 50
                        //     }, 100);


                        // $('.main-c-wrap').removeClass('moveout');

                        // setTimeout(function(){                            
                        //     $('body').css('opacity', 1);
                        // }, 1500);
                        bios();
                        
                        
                                    
                      },
            });
           });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('document').ready(function(){

          // Testimonials //
          var slider = $("#lightSlider").lightSlider({
            item: 1,
            mode: 'fade',
            controls: false,
            loop: true,
            auto:true,
            pause: 5000,
            pager: false,
            enableDrag: false,
            adaptiveHeight: true,
          }); 

          $('.test-next').click(function(){
            slider.goToNextSlide();
          });

          $('.test-prev').click(function(){
            slider.goToPrevSlide();
          });


        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page': {
      init: function() {
        // JavaScript to be fired on the about us page
        var windowHeight = $(window).width();
        var sidebarLength  = '';
        var btnLenth = '';
        $('document').ready(function(){
          

          sidebarLength = $('.stickyside li.parent').length;
          btnLenth = ('.stickyside .sidebtn').length;
          
          stickySide()
        });

        $(window).resize(function(){ stickySide(); });
        
        function stickySide(){
          if(windowHeight <= 768 & sidebarLength <= '1' & sidebarLength < '1') {
            $('.submenu').hide();
          }
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
